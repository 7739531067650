 <template>
    <el-dialog :visible.sync="visible" :title="modalObj.title" :width="modalObj.width" :modal="modalObj.modal" :before-close="modalClose" center :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <!-- <el-form-item label="任务名称：" prop="taskName">
                <el-input v-model="ruleForm.taskName" placeholder="请输入任务名称" ></el-input>
            </el-form-item> -->
            <!-- <el-upload :auto-upload="false" action="" :show-file-list="false" :on-change="onChange" :limit="1" style="float: left; margin-right: 30px">
                <el-button class="export"> <img src="~img/alibaba/import.png" />excel文件导入 </el-button>
            </el-upload> -->
            <el-upload :auto-upload="false" action="" :show-file-list="false" :on-change="onChange" :limit="1" style="float: left; margin-right: 30px" >
                <el-button class="export" > 选择文件 </el-button>
            </el-upload>
            <!--弹出层按钮居中-->
            <div>
                <el-button @click="modalClose" class="export" style="background-color: #999; color: #333">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
import { goodsSkuPage, goodsReserve } from "@/api/goods/goods"

export default {
    name: "timeTask-add-layer",
    data() {
        // 验证
        let validateTaskName = (rule, value, callback) => {
            let _this = this
            var pattern = /^.{1,12}$/
            if (!pattern.test(value)) {
                callback(new Error("任务名称长度在2到12个字符！"))
            } else {
                callback()
            }
        }
        return {
            ruleForm: {
                taskName: ""
            },
            rules: {
                taskName: [
                    { required: true, message: "请输入任务名称!", trigger: "blur" },
                    { validator: validateTaskName, trigger: "change" }
                ]
            }
        }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        modalObj: {
            type: Object,
            default: false
        }
    },
    mounted() {
        //表单初始
    },
    methods: {
        // 信息提交
        submitForm(formName, formData) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //判断modalObj中id是否为空，决定是编辑还是添加请求操作；
                    this.modalClose()
                } else {
                    //验证失败处理
                    return false
                }
            })
        },
        // 关闭弹出框
        modalClose() {
            this.$emit("update:visible", false) // 直接修改父组件的属性
        },
        async onChange(file) {
            let xlsx = require("xlsx")
            let dataBinary = await this.$util.readFile(file.raw)
            let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true })
            let workSheet = workBook.Sheets[workBook.SheetNames[0]]
            const data = xlsx.utils.sheet_to_json(workSheet)

            let skuList = []
            if (!data.length) return $this.message("导入结果为空")
            data.forEach((item) => {
                skuList.push({ sku_id: item["国际条码"], num: item["数量"] })
            })
            console.log(skuList)
            this.loading = true
            goodsReserve({ sku_list: skuList })
                .then((res) => {
                    if (res.code != 0) throw new Error(res.message || "导入失败")
                    let list = []
                    res.data.forEach((item) => {
                        item.num = 0
                        list.push(item)
                    })
                    this.goodsList = list
                    this.total == list.length
                    this.loading = false
                })
                .catch((e) => {
                    this.loading = false
                    $this.message(e.message || "导入失败")
                })
                this.modalClose();
        }
    }
}
</script>
<style scoped>
.export {
    width: 150px;
    height: 40px;
    /* margin: 30px 0; */
    border-radius: 5px;
    background-color: #ed6030;
    color: #ffffff;
    font-size: 14px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-left: 107px;
}
</style>